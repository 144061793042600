import httpClient from "./httpClient";

const resource = "accountable_transactions";

export function fetchAccountableTransactions(params) {
	return httpClient.get(`/${resource}`, { params });
}

export function fetchAccountableTransaction(id) {
	return httpClient.get(`/${resource}/${id}`);
}

export function getParams() {
	return httpClient.get(`/${resource}/params`);
}