import { mapMutations, mapState } from "vuex";
import { FINISHED_LOADING_CONTENT, START_LOADING_CONTENT } from "../store/mutations.js";

export default {
  methods: {
    ...mapMutations({
      $startLoading: START_LOADING_CONTENT,
      $finishedLoading: FINISHED_LOADING_CONTENT,
    }),
  },
  computed: {
    ...mapState({
      loading: ({ loadingContent }) => loadingContent,
    }),
  },
};
