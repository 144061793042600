<template>
  <v-container
    class="pt-0"
    fluid>
    <v-row
      align="center"
      justify="center">
      <v-col
        cols="12"
        sm="8">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            {{ $t("crudActions.indexModel", { model: $t("models.accountableTransactionsGroups.multiple") }) }}
            <v-btn
              small
              color="primary"
              @click="showCreateDialog = true">
              {{ $t("crudActions.createModel", { model: $t("models.accountableTransactionsGroups.single") }) }}
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                class="pa-3"
                cols="12"
                md="6">
                <date-picker
                  :value.sync="filters.startDate"
                  :label="$t('fields.startDate')"
                  outlined />
              </v-col>

              <v-col
                class="pa-3"
                cols="12"
                md="6">
                <date-picker
                  :value.sync="filters.endDate"
                  :label="$t('fields.endDate')"
                  outlined />
              </v-col>
            </v-row>
            <v-row
              class="px-3"
              justify="end"
              align="center">
              <v-btn
                color="success"
                @click="getTransactionGroups">
                {{ $t("actions.filter") }}
              </v-btn>
            </v-row>
            <br>
            <data-table
              dense
              :loading="loading"
              :headers="tableColumns"
              :items="accountableTransactionsGroups">
              <template #item.number="{ item }">
                <div
                  class="text-center" >
                  <a
                    @click="openDetailsModal(item.number)">
                    {{ item.number }}
                  </a>
                </div>
              </template>
            </data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <create-form :dialog.sync="showCreateDialog" />
    <accountable-transactions-group-details-dialog
      :show-dialog="showDetailsDialog"
      :transaction-group-id="transactionGroupId"
      @close="showDetailsDialog = false">
    </accountable-transactions-group-details-dialog>
  </v-container>
</template>

<script>
import { fetchAccountableTransactionsGroups } from "@/api/accountableTransactionsGroup.js";
import CreateForm from "./AccountableTransactionsGroupCreate";
import ContentLoader from "../../mixins/ContentLoader.js";
import { i18n } from "@/i18n";
import DatePicker from "../../components/inputs/DatePicker";
import moment from "moment";
import DataTable from "@/components/DataTable.vue";
import AccountableTransactionsGroupDetailsDialog
  from "@/pages/accountableTransactionsGroup/AccountableTransactionsGroupDetailsDialog.vue";

function data() {
  return {
    accountableTransactionsGroups: [],
    filters: {
      startDate: moment().
      format("YYYY-MM-DD"),
      endDate: moment().
      format("YYYY-MM-DD"),
    },
    showCreateDialog: false,
    showDetailsDialog: false,
    transactionGroupId: null,
  };
}

export default {
  name: "AccountableTransactionsGroupIndex",
  components: {
    DataTable,
    CreateForm,
    DatePicker,
    AccountableTransactionsGroupDetailsDialog,
  },
  mixins: [ContentLoader],
  data,
  computed: {
    tableColumns() {
      return [
        {
          value: "number",
          text: i18n.t("views.transactionGroup.index.tableColumns.number"),
          align: 'center'
        },
        {
          value: "date",
          text: i18n.t("views.transactionGroup.index.tableColumns.date"),
          align: 'center'
        },
        {
          value: "time",
          text: i18n.t("views.transactionGroup.index.tableColumns.time"),
          align: 'center'
        },
        {
          value: "created_by",
          text: i18n.t("views.transactionGroup.index.tableColumns.createdBy"),
          align: 'center'
        },
        {
          value: "is_automatic",
          text: i18n.t("views.transactionGroup.index.tableColumns.isAutomatic"),
          align: 'center'
        },
        {
          value: "notes",
          text: i18n.t("views.transactionGroup.index.tableColumns.notes"),
          align: 'center'
        },
        {
          value: "action",
          text: "",
          align: 'center'
        },
      ];
    },
  },
  watch: {
    "showCreateDialog"() {
      if (!this.showCreateDialog) {
        this.getTransactionGroups();
      }
    },
  },
  async mounted() {
    this.$startLoading();
    
    try {
      await this.getTransactionGroups();
      
      await this.$nextTick();
    } finally {
      this.$finishedLoading();
    }
  },
  methods: {
    async getTransactionGroups() {
      return fetchAccountableTransactionsGroups(this.filters).
      then(response => {
        const { accountableTransactionsGroups } = response.data;
        this.accountableTransactionsGroups = accountableTransactionsGroups.map(tg => ({
          number: tg.id,
          date: moment(tg.effectiveDate).
          format("DD-MM-YYYY"),
          time: moment(tg.effectiveDate).
          format("hh:mm A"),
          created_by: tg.createdBy,
          is_automatic: tg.isAutomatic,
          notes: tg.notes,
        }));
      });
    },
    openDetailsModal(id) {
      this.showDetailsDialog = true;
      this.transactionGroupId = id;
    },
  },
};
</script>
