import httpClient from "./httpClient";
import AccountableEntityShowCategory from "@/imported/AccountableEntityShowCategory.json";

const resource = "accountable_entities";

export function createAccountableEntity(accountableEntity) {
	return httpClient.post(`/${resource}`, { accountableEntity });
}

export function fetchAccountableEntities() {
	return httpClient.get(`/${resource}`);
}

export function fetchAccountableEntity(id) {
	return httpClient.get(`/${resource}/${id}`, { params: { category: AccountableEntityShowCategory.general }});
}

export function updateAccountableEntity(id, accountableEntity) {
	return httpClient.put(`/${resource}/${id}`, { accountableEntity });
}

export function getParams(accountableEntityId) {
	return httpClient.get(`/${resource}/params`, { params: { accountableEntityId } });
}

export function getAccountableEntityBalance(id) {
	return httpClient.get(`/${resource}/${id}`, { params: { category: AccountableEntityShowCategory.balance }});
}
