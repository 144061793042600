<template>
  <div>
    <span :id="`date_picker_${_uid}`">
      <v-text-field
        v-model="model"
        :label="label"
        :loading="loading"
        readonly
        :hide-details="hideDetails"
        :dense="dense"
        :outlined="outlined">
        <template #prepend-inner>
          <font-awesome-icon icon="calendar"></font-awesome-icon>
        </template>
      </v-text-field>
    </span>
    <v-menu
      v-model="open"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
      :activator="`#date_picker_${_uid}`">
      <v-date-picker
        v-model="model"
        @input="open = false"
        @change="$emit('change', $event)">
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "DatePicker",
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      }
    }
  }
};
</script>

<style scoped></style>
