import httpClient from "./httpClient";

const resource = "accountable_transactions_groups";

export function createAccountableTransactionsGroup(accountableTransactionsGroup) {
	return httpClient.post(`/${resource}`, { accountableTransactionsGroup });
}

export function fetchAccountableTransactionsGroups(params) {
	return httpClient.get(`/${resource}`, { params });
}

export function fetchAccountableTransactionsGroupDetails(params) {
	return httpClient.get(`/${resource}/${params.id}`);
}

export function fetchAccountableTransactionsGroup(id) {
	return httpClient.get(`/${resource}/${id}`);
}

export function updateAccountableTransactionsGroup(id, accountableTransactionsGroup) {
	return httpClient.put(`/${resource}/${id}`, { accountableTransactionsGroup });
}
