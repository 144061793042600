<template>
  <v-dialog
    :value="showDialog"
    @input="$emit('close')">
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th class="text-center">
              Tipo
            </th>
            <th class="text-center">
              Entidad #1
            </th>
            <th class="text-center">
              Codigo entidad #1
            </th>
            <th class="text-center">
              Entidad #2
            </th>
            <th class="text-center">
              Codigo entidad #2
            </th>
            <th class="text-center">
              Saldo inicial de Entidad #1
            </th>
            <th class="text-center">
              Saldo inicial de Entidad #2
            </th>
            <th class="text-center">
              Debito
            </th>
            <th class="text-center">
              Credito
            </th>
            <th class="text-center">
              Saldo final de Entidad #1
            </th>
            <th class="text-center">
              Saldo final de Entidad #2
            </th>
            <th class="text-center">
              Notas
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in transactions"
            :key="item.name"
            class="text-center">
            <td>{{ item.type }}</td>
            <td>{{ item.sourceName }}</td>
            <td>{{ item.sourceCode }}</td>
            <td>{{ item.destinationName }}</td>
            <td>{{ item.destinationCode }}</td>
            <td>{{ item.sourceOpeningBalance }}</td>
            <td>{{ item.destinationOpeningBalance }}</td>
            <td>{{ item.debit }}</td>
            <td>{{ item.credit }}</td>
            <td>{{ item.sourceClosingBalance }}</td>
            <td>{{ item.destinationClosingBalance }}</td>
            <td>
              <v-tooltip
                color="primary"
                top>
                <template #activator="{ on, attrs }">
                  <font-awesome-icon
                    icon="comment-dots"
                    size="2x"
                    v-bind="attrs"
                    v-on="on" />
                </template>
                <span> {{ item.notes }} </span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-dialog>
</template>

<script>

function data() {
  return {
    transactions: [],
  };
}

import { fetchAccountableTransactionsGroupDetails } from "@/api/accountableTransactionsGroup.js";

export default {
  name: "AccountableTransactionsGroupDetailsDialog",
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    transactionGroupId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data,
  watch: {
    transactionGroupId(newValue, _) {
      fetchAccountableTransactionsGroupDetails({ id: newValue }).
      then(response => {
        this.transactions = response.data.transactions;
      });
    },
  },
};
</script>
