import HttpStatus from "http-status";

export default {
	methods: {
		handleErrorResponse(observer, error) {
			if (error?.response?.status === HttpStatus.UNPROCESSABLE_ENTITY) {
				const { failures } = error.response.data;

				observer.setErrors(failures);
			}
		},
	},
};
