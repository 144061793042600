<template>
  <v-row justify="center">
    <v-dialog
      v-model="model"
      fullscreen
      hide-overlay
      persistent
      no-click-animation
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar
          dark
          color="primary">
          <v-btn
            icon
            dark
            @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{
              $t("crudActions.createModel", { model: $t("models.accountableTransactionsGroups.single") })
            }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              color="grey"
              text
              @click="closeDialog">
              {{ $t("crudActions.cancel") }}
            </v-btn>
            <v-btn
              dark
              text
              :disabled="accountableTransactions.length === 0"
              @click="submitTransactions">
              {{ $t("crudActions.create") }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <validation-observer ref="validationObserver">
            <v-row>
              <v-col
                cols="12"
                md="6">
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.transaction_type"
                  name="transaction_type">
                  <v-autocomplete
                    v-model="form.transaction_type"
                    :items="transactionTypes"
                    :error-messages="errors"
                    name="transaction_type"
                    item-text="name"
                    item-value="id"
                    auto-select-first
                    outlined
                    :label="$t('fields.type')" />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.source_entity_id"
                  name="source_entity_id">
                  <v-autocomplete
                    v-model="form.source_entity_id"
                    :items="sourceEntities()"
                    :error-messages="errors"
                    name="source_entity_id"
                    item-text="name"
                    item-value="id"
                    auto-select-first
                    outlined
                    :label="sourceEntityLabel()" />
                </validation-provider>
                <v-autocomplete
                  v-model="form.source_entity_id"
                  :items="sourceEntities()"
                  item-text="code"
                  item-value="id"
                  auto-select-first
                  outlined
                  :label="$t('fields.code')" />
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.destination_entity_id"
                  name="destination_entity_id">
                  <v-autocomplete
                    v-model="form.destination_entity_id"
                    :items="destinationEntities()"
                    :error-messages="errors"
                    name="destination_entity_id"
                    item-text="name"
                    item-value="id"
                    auto-select-first
                    outlined
                    :label="destinationEntityLabel()" />
                </validation-provider>
                <v-autocomplete
                  v-model="form.destination_entity_id"
                  :items="destinationEntities()"
                  item-text="code"
                  item-value="id"
                  auto-select-first
                  outlined
                  :label="$t('fields.code')" />
                <v-text-field
                  v-model="form.notes"
                  :label="$t('fields.notes')"
                  outlined
                  type="text" />
                <v-row justify="end">
                  <v-col cols="auto">
                    <v-switch
                      v-model="rememberNotes"
                      class="mt-0"
                      inset
                      :label="$t('actions.rememberAttr', { attr: 'Notes' })" />
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="6">
                <v-text-field
                  v-model="form.opening_balance_source_entity"
                  :label="$t('views.transactionGroup.create.fields.entityOpeningBalance', { target: sourceEntityName() })"
                  icon="mdi-information-variant"
                  type="text"
                  class="balance_wrapper"
                  outlined
                  disabled>
                  <template
                    v-if="sourceIsPool"
                    #append>
                    <v-btn
                      color="orange"
                      dark
                      @click="handlePurseClear(sourceEntityName(), form.source_entity_id )">
                      {{ $t("views.transactionGroup.create.fields.wallet") }}
                      <v-icon
                        color="white"
                        class="ml-3"
                        dark>
                        mdi-cash-refund
                      </v-icon>
                    </v-btn>
                    <v-btn
                      dark
                      color="orange"
                      class="ml-3"
                      @click="handleJackpotClear(sourceEntityName(), form.source_entity_id)">
                      {{ $t("views.transactionGroup.create.fields.jackpot") }}
                      <v-icon
                        color="white"
                        class="ml-3"
                        dark>
                        mdi-cash-refund
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <v-text-field
                  v-model="form.opening_balance_destination_entity"
                  :label="$t('views.transactionGroup.create.fields.entityOpeningBalance', { target: destinationEntityName() })"
                  type="text"
                  class="balance_wrapper"
                  outlined
                  disabled>
                  <template
                    v-if="destinationIsPool"
                    #append>
                    <v-btn
                      color="orange"
                      dark
                      @click="confirmPurseClear({name: destinationEntityName(), id: form.destination_entity_id})">
                      {{ $t("views.transactionGroup.create.fields.wallet") }}
                      <v-icon
                        color="white"
                        class="ml-3"
                        dark>
                        mdi-cash-refund
                      </v-icon>
                    </v-btn>
                    <v-btn
                      color="orange"
                      class="ml-3"
                      dark
                      @click="confirmJackpotClear({name: sourceEntityName(), id: form.source_entity_id})">
                      {{ $t("views.transactionGroup.create.fields.jackpot") }}
                      <v-icon
                        color="white"
                        class="ml-3"
                        dark>
                        mdi-cash-refund
                      </v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.debit"
                  name="debit">
                  <v-text-field
                    v-model="form.debit"
                    :label="$t('fields.debit')"
                    outlined
                    :error-messages="errors"
                    name="debit"
                    :disabled="isCurrentType([accountableTransactionTypes.charge])"
                    type="number"
                    @keydown.enter="addTransaction" />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="validationRules.credit"
                  name="credit">
                  <v-text-field
                    v-model="form.credit"
                    :label="$t('fields.credit')"
                    outlined
                    :error-messages="errors"
                    name="credit"
                    :disabled="isCurrentType([accountableTransactionTypes.payment])"
                    type="number"
                    @keydown.enter="addTransaction" />
                </validation-provider>
                <v-text-field
                  v-model="form.closing_balance_source_entity"
                  :label="$t('views.transactionGroup.create.fields.entityClosingBalance', { target: sourceEntityName() })"
                  outlined
                  disabled
                  type="text" />
                <v-text-field
                  v-model="form.closing_balance_destination_entity"
                  :label="$t('views.transactionGroup.create.fields.entityClosingBalance', { target: destinationEntityName() })"
                  outlined
                  disabled
                  type="text" />
              </v-col>
            </v-row>
          </validation-observer>
          <br>
          <v-text-field
            v-model="groupNotes"
            :label="$t('views.transactionGroup.create.fields.transactionGroupNotes')"
            outlined
            type="text" />
          <v-data-table
            :loading="loading"
            :headers="tableColumns"
            :items="accountableTransactions">
            <template v-slot:item.notes="{ item }">
              <v-tooltip
                v-if="item.notes"
                bottom>
                <template v-slot:activator="{ on, attrs }">
                  <font-awesome-icon
                    icon="comment-alt"
                    v-bind="attrs"
                    v-on="on" />
                </template>
                <span>{{ item.notes }}</span>
              </v-tooltip>
            </template>
            <template v-slot:item.delete="{ item }">
              <v-btn
                icon
                @click="deleteTransaction(item)">
                <font-awesome-icon
                  icon="trash" />
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="showSnackbar"
      :color="snackbarColor"
      top>
      {{ snackbarText }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { getParams } from "@/api/accountableTransaction.js";
import { getAccountableEntityBalance } from "@/api/accountableEntity.js";
import { createAccountableTransactionsGroup } from "@/api/accountableTransactionsGroup.js";
import ContentLoader from "../../mixins/ContentLoader.js";
import { ValidationObserver } from "vee-validate";
import Validatable from "../../mixins/Validatable.js";
import AccountableTransactionType from "@/imported/AccountableTransactionType.json";
import EntityType from "@/imported/EntityType.json";
import { resetData } from "../../utils/ComponentHelper";
import { i18n } from "../../i18n/index.js";
import { confirmJackpotClear } from "@/utils/jackpot.js";
import { confirmPurseClear } from "@/utils/wallet.js";

function data() {
  return {
    form: {
      transaction_type: null,
      source_entity_id: null,
      destination_entity_id: null,
      opening_balance_source_entity: "N/A",
      opening_balance_destination_entity: "N/A",
      debit: 0,
      credit: 0,
      closing_balance_source_entity: "N/A",
      closing_balance_destination_entity: "N/A",
      notes: null,
    },
    transactionTypes: [],
    entities: [],
    users: [],
    accountableTransactions: [],
    rememberNotes: false,
    groupNotes: null,
    entityTypes: EntityType,
    entitiesBalanceMap: {},
    accountableTransactionTypes: AccountableTransactionType,
    showSnackbar: false,
    snackbarText: "",
    snackbarColor: "Red",
  };
}

export default {
  name: "AccountableTransactionsGroupCreate",
  components: {
    ValidationObserver,
  },
  mixins: [ContentLoader, Validatable],
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data,
  computed: {
    tableColumns() {
      return [
        {
          value: "transaction_type_name",
          text: i18n.t("views.transactionGroup.create.tableColumns.type"),
        },
        {
          value: "source_entity_name",
          text: i18n.t("views.transactionGroup.create.tableColumns.sourceEntity"),
        },
        {
          value: "source_entity_code",
          text: i18n.t("views.transactionGroup.create.tableColumns.sourceEntityCode"),
        },
        {
          value: "destination_entity_name",
          text: i18n.t("views.transactionGroup.create.tableColumns.destinationEntity"),
        },
        {
          value: "destination_entity_code",
          text: i18n.t("views.transactionGroup.create.tableColumns.destinationEntityCode"),
        },
        {
          value: "opening_balance_source_entity",
          text: i18n.t("views.transactionGroup.create.tableColumns.sourceEntityOpeningBalance"),
        },
        {
          value: "opening_balance_destination_entity",
          text: i18n.t("views.transactionGroup.create.tableColumns.destinationEntityOpeningBalance"),
        },
        {
          value: "debit",
          text: i18n.t("views.transactionGroup.create.tableColumns.debit"),
        },
        {
          value: "credit",
          text: i18n.t("views.transactionGroup.create.tableColumns.credit"),
        },
        {
          value: "closing_balance_source_entity",
          text: i18n.t("views.transactionGroup.create.tableColumns.sourceEntityClosingBalance"),
        },
        {
          value: "closing_balance_destination_entity",
          text: i18n.t("views.transactionGroup.create.tableColumns.destinationEntityClosingBalance"),
        },
        {
          value: "notes",
          text: i18n.t("views.transactionGroup.create.tableColumns.notes"),
        },
        {
          value: "delete",
          text: i18n.t("crudActions.delete"),
        },
      ];
    },
    validationRules() {
      return {
        transaction_type: "required",
        source_entity_id: "required|not_same:@destination_entity_id",
        destination_entity_id: "required|not_same:@source_entity_id",
        debit: "required" + (this.isCurrentType([this.accountableTransactionTypes.payment]) ? "|min_value:0" : ""),
        credit: "required" + (this.isCurrentType([this.accountableTransactionTypes.charge]) ? "|min_value:0" : ""),
      };
    },
    sourceIsPool() {
      const selectedSourceEntity = this.entities.filter(e => e.id === this.form.source_entity_id)[0];
      
      return selectedSourceEntity ? selectedSourceEntity.entityType === EntityType.bettingPool : null;
    },
    destinationIsPool() {
      const selectedDestinationEntity = this.entities.filter(e => e.id === this.form.destination_entity_id)[0];
      
      return selectedDestinationEntity ? selectedDestinationEntity.entityType === EntityType.bettingPool : null;
    },
    model: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("update:dialog", value);
      },
    },
  },
  watch: {
    "dialog"() {
      if (this.dialog) {
        this.fetchParams();
      }
    },
    "form.transaction_type"() {
      this.resetFormData();
    },
    "form.source_entity_id"() {
      if (this.form.source_entity_id) {
        this.entitiesBalanceMap[this.form.source_entity_id] ?
          this.updateEntitiesBalances() : this.fetchEntityBalance(this.form.source_entity_id);
      }
    },
    "form.destination_entity_id"() {
      if (this.form.destination_entity_id) {
        this.entitiesBalanceMap[this.form.destination_entity_id] ?
          this.updateEntitiesBalances() : this.fetchEntityBalance(this.form.destination_entity_id);
      }
    },
    "form.debit"() {
      this.updateEntitiesBalances();
    },
    "form.credit"() {
      this.updateEntitiesBalances();
    },
  },
  methods: {
    closeDialog() {
      this.$emit("update:dialog", false);
    },
    isCurrentType(types) {
      return types.includes(this.form.transaction_type);
    },
    sourceEntities() {
      return this.entities;
    },
    sourceEntityName() {
      if (this.form.source_entity_id) {
        const entity = this.entities.find(e => (e.id === this.form.source_entity_id));
        return entity.name;
      }
      return i18n.t("views.transactionGroup.create.fields.sourceEntity");
    },
    sourceEntityLabel() {
      return i18n.t("views.transactionGroup.create.fields.sourceEntity");
    },
    destinationEntities() {
      if ([AccountableTransactionType.charge, AccountableTransactionType.payment].includes(this.form.transaction_type)) {
        return this.entities.filter(e => ([EntityType.bank].includes(e.entityType)));
      }
      return this.entities;
    },
    destinationEntityName() {
      if (this.form.destination_entity_id) {
        const entity = this.entities.find(e => (e.id === this.form.destination_entity_id));
        return entity.name;
      }
      return i18n.t("views.transactionGroup.create.fields.destinationEntity");
    },
    destinationEntityLabel() {
      if ([AccountableTransactionType.charge, AccountableTransactionType.payment].includes(this.form.transaction_type)) {
        return i18n.t("enumerations.entityType.bank");
      }
      return i18n.t("views.transactionGroup.create.fields.destinationEntity");
    },
    async addTransaction() {
      const { validationObserver } = this.$refs,
        isValid = await validationObserver.validate();
      
      if (isValid) {
        const type = this.transactionTypes.find(t => (this.form.transaction_type === t.id));
        const sourceEntity = this.entities.find(e => (this.form.source_entity_id === e.id));
        const destinationEntity = this.entities.find(e => (this.form.destination_entity_id === e.id));
        const lastId = this.accountableTransactions[this.accountableTransactions.length - 1]?.local_id || 0;
        this.accountableTransactions.push({
          ...this.form,
          local_id: lastId + 1,
          transaction_type_name: type.name,
          source_entity_name: sourceEntity.name,
          source_entity_code: sourceEntity.code,
          source_opening_balance: this.entitiesBalanceMap[sourceEntity.id],
          destination_entity_name: destinationEntity.name,
          destination_entity_code: destinationEntity.code,
          destination_opening_balance: this.entitiesBalanceMap[destinationEntity.id],
          effective_date: new Date().toLocaleString(),
        });
        this.entitiesBalanceMap[sourceEntity.id] = parseFloat(this.form.closing_balance_source_entity.substr(1));
        this.entitiesBalanceMap[destinationEntity.id] = parseFloat(this.form.closing_balance_destination_entity.substr(1));
        this.resetFormData();
        if (!this.rememberNotes) {
          this.form.notes = null;
        }
      }
    },
    deleteTransaction(item) {
      this.accountableTransactions = this.accountableTransactions.filter(t => (t.local_id !== item.local_id));
      
      // Update balances of transactions on list
      const amount = parseFloat(item.credit) - parseFloat(item.debit);
      this.accountableTransactions = this.accountableTransactions.map(t => {
        if (item.local_id < t.local_id && [item.source_entity_id, item.destination_entity_id].includes(t.source_entity_id)) {
          t.source_opening_balance += amount;
          t.opening_balance_source_entity = "$" + t.source_opening_balance.toFixed(2);
          t.closing_balance_source_entity = "$" + (t.source_opening_balance + parseFloat(t.debit) - parseFloat(t.credit)).toFixed(2);
        }
        if (item.local_id < t.local_id && [item.source_entity_id, item.destination_entity_id].includes(t.destination_entity_id)) {
          t.destination_opening_balance -= amount;
          t.opening_balance_destination_entity = "$" + t.destination_opening_balance.toFixed(2);
          t.closing_balance_destination_entity = "$" + (t.destination_opening_balance - parseFloat(t.debit) + parseFloat(t.credit)).toFixed(2);
        }
        return t;
      });
      
      // Update balances of current transaction
      this.entitiesBalanceMap[item.source_entity_id] += parseFloat(item.credit) - parseFloat(item.debit);
      this.entitiesBalanceMap[item.destination_entity_id] += parseFloat(item.debit) - parseFloat(item.credit);
      this.updateEntitiesBalances();
    },
    fetchParams() {
      resetData(this.$data, data);
      return getParams().
      then(response => {
        const { transactionTypes, entities, users } = response.data;
        this.transactionTypes = transactionTypes;
        this.entities = entities;
        this.users = users;
        this.form.transaction_type = transactionTypes[0]?.id;
      });
    },
    fetchEntityBalance(entityId) {
      getAccountableEntityBalance(entityId).
      then(response => {
        this.entitiesBalanceMap[entityId] = parseFloat(response.data.balance || 0);
        this.updateEntitiesBalances();
      });
    },
    async submitTransactions() {
      const params = {
        notes: this.groupNotes,
        accountable_transactions_attributes: this.accountableTransactions.map(t => ({
          debit: t.debit,
          credit: t.credit,
          source_accountable_entity_id: t.source_entity_id,
          destination_accountable_entity_id: t.destination_entity_id,
          transaction_type: t.transaction_type,
          effective_date: t.effective_date,
          notes: t.notes,
        })),
      };
      createAccountableTransactionsGroup(params).
      then(() => {
        this.$emit("update:dialog", false);
      }).
      catch(error => console.error(error));
    },
    updateEntitiesBalances() {
      if (this.form.source_entity_id) {
        this.form.opening_balance_source_entity = "$" + this.entitiesBalanceMap[this.form.source_entity_id].toFixed(2);
        this.form.closing_balance_source_entity = "$" + (parseFloat(this.entitiesBalanceMap[this.form.source_entity_id]) + parseFloat(this.form.debit) - parseFloat(this.form.credit)).toFixed(2);
      }
      
      if (this.form.destination_entity_id) {
        this.form.opening_balance_destination_entity = "$" + this.entitiesBalanceMap[this.form.destination_entity_id].toFixed(2);
        this.form.closing_balance_destination_entity = "$" + (parseFloat(this.entitiesBalanceMap[this.form.destination_entity_id]) - parseFloat(this.form.debit) + parseFloat(this.form.credit)).toFixed(2);
      }
    },
    resetFormData() {
      const { validationObserver } = this.$refs;
      validationObserver.reset();
      this.form = {
        ...data().form,
        transaction_type: this.form.transaction_type,
        notes: this.form.notes,
      };
    },
    confirmJackpotClear,
    confirmPurseClear,
    handlePurseClear(name, entityId) {
      this.confirmPurseClear({ name: name, id: entityId }).
      then(() => {
        this.fetchEntityBalance(entityId);
      });
    },
    handleJackpotClear(name, entityId) {
      this.confirmJackpotClear({ name: name, id: entityId }).
      then(() => {
        this.fetchEntityBalance(entityId);
      });
    },
  },
};
</script>

<style
  lang="scss"
  scoped>
div.tab-with-error {
  background: #ffb8b8;
  border-top: 2px solid red;
}

::v-deep .balance_wrapper {
  pointer-events: all;

  .v-input__append-inner {
    margin-top: 1% !important;
  }
}

</style>
