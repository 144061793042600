import { emptyJackpot } from "@/api/jackpot.js";

//Requires this.snackbar to be defined
//Requires $swal instance to be globally available
//Requires item to have two attributes, name, id
export function confirmJackpotClear(item) {
  return new Promise((resolve, reject) => {
    this.$swal.fire({
      position: "center",
      icon: "warning",
      title: this.$t("views.bettingPools.index.confirm_jackpot_clear", { pool_name: item.name }),
      showCancelButton: true,
      showConfirmButton: true,
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).
    then(swalResponse => {
      if (swalResponse.isConfirmed) {
        emptyJackpot(item.id).
        then(response => {
          this.$swal.fire({
            position: "center",
            icon: "success",
            title: this.$t("views.transactionGroup.create.jackpotAddedToBalance"),
            showConfirmButton: false,
            timer: 1500,
          }).
          then(() => item.loading = false);
          resolve(response);
        }).
        catch(reject);
      }
    });
  });
}
