<template>
  <div>
    <v-row>
      <v-col
        offset-sm="8"
        sm="4"
        offset-lg="9"
        lg="3">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('dataTable.quickSearch')"
          single-line
          outlined
          dense
          hide-details />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :search="search"
          :loading="loading"
          :headers="headers"
          mobile-breakpoint="400"
          :dense="dense"
          :items="items">
          <slot
            v-for="slot in Object.keys($slots)"
            :slot="slot"
            :name="slot" />
          <template
            v-for="slot in Object.keys($scopedSlots)"
            :slot="slot"
            slot-scope="scope">
            <slot
              :name="slot"
              v-bind="scope" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "DataTable",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    headers: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      search: "",
    };
  },
};
</script>

<style scoped>

</style>
